@tailwind base;

html {
  @apply font-body;
}
h2 {
  @apply uppercase font-bold text-3xl text-tcc-yellow;
}

h3 {
  @apply font-bold text-2xl my-6;
}

h4 {
  @apply font-bold text-lg text-tcc-yellow my-4;
}

@tailwind components;

@tailwind utilities;

.hero-container {
  height: 500px;
  background-image: url("/img/hero-1.jpg");
  mask-image: url("/img/hero-mask.svg");
  mask-size: cover;
  mask-position: bottom;
}

.header-logo {
  width: 223px;
  height: 110px;
}

.menu-item:hover::before {
  top: 0px;
  left: 0px;
  transition: box-shadow 0.5s ease-in-out 0s;
  position: absolute;
  bottom: 0px;
  -webkit-transition: box-shadow 0.5s ease-in-out 0s;
  pointer-events: none;
  right: 0px;
  content: "";
  box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0),
    inset 0px 0px 0px rgba(0, 0, 0, 0);
}
